import React, { Component } from 'react';
import { Router, Route, IndexRoute, browserHistory } from 'react-router';
import {useHistory, Link } from 'react-router-dom';

const Product = ({ location }) => {
  const { state = {} } = location;
  const { modal } = state;
  return (
    <div className={modal ? "modal" : undefined}>
      {modal && <Link to="/">Close</Link>}
      <div>
        <img src="https://source.unsplash.com/random" />
      </div>
    </div>
  );
};

export default Product;